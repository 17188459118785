import { KeycloakService } from 'keycloak-angular';
import { SettingsHttpService } from 'src/services/settings.http.service';

 export function initializeKeycloak(keycloak: KeycloakService, appConfig: SettingsHttpService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      let config1: any;
      let env: any;
      try {
        config1 = await appConfig.initializeApp();
        sessionStorage.setItem('apiURL', JSON. stringify(config1));
      } catch (error) {
        // Here you should properly deal with the error
        reject(new Error('Config not loaded'));
        return;
      }
      try {

        await keycloak.init({
            config: {
                url: config1.KeycloakAuthority,
                realm: config1.KeycloakRealm,
                clientId: config1.KeycloakClient
            },
            initOptions: {
              onLoad: 'check-sso',
               flow:'standard',
             pkceMethod:'S256'
          },
           bearerExcludedUrls: [],
           });
        resolve(true);
      } catch (error) {
                // Here you should properly deal with the error
        reject(new Error('Keycloak not loaded'));
      }
    });
  };
}