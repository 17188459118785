import settings from '../../../assets/settings.json';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApplicationService } from '../../../services/application.service';
import { AuthService } from '../../../services/auth.service';
import { EncrDecrService } from '../../../services/EncrDecrService';
import { JwtHelperService } from '@auth0/angular-jwt';
import { KeycloakService } from 'keycloak-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/services/settings.service';
import { SuccesspopupComponent } from '../dashboard/modals/successpopup/successpopup.component';
import { interval } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { UserRole } from 'src/app/models/userrole';
// import { JwtModule } from '@auth0/angular-jwt';



const Jwthelper = new JwtHelperService();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public state: any = {
    form: {

    }
  };
  loginErrorMessage: string;
  passwordError = false;
  isView = true;
  showEmailError = false;
  showError;
  emailSentMessage;
  order: any;
  modalRef;
  loading = false;
  userRoles;
  captchaToken;
  capchaError;
  CaptchanotVerified;
  resetCaptcha = false;
  showCaptcha = false;
  enablecaptch = false;
  RefreshTokenInterval: number = 270;
  // private jwtHelper: JwtHelperService,
  constructor(private authService: AuthService,
    private encrDecrService: EncrDecrService,
    private router: Router, private modalService: NgbModal, private appSvc: ApplicationService,
    private route: ActivatedRoute, private mySettingsService: SettingsService, private keycloak: KeycloakService) {
   // sessionStorage.clear();
    // localStorage.clear();
    this.enablecaptch = this.mySettingsService.settings.enablecaptch;

    this.route.queryParams.subscribe(params => {
      this.order = params;
      console.log(this.order);
  });
    //  this.keycloak.logout();
    //   this.modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    //   this.appSvc.setUserLogin(false);
    //   sessionStorage.setItem('isUserLogin','false');
    //   this.modalRef.componentInstance.message = 'Session expired! Another user logged in with same credentials.';
    //   this.modalRef.result.then(ret => {
    //     if (ret === 'close') {
    //       this.router.navigate(['/']);
    //       this.modalService.dismissAll();
    //     }
    //   });
    // }
  }
  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    }  catch (Error) {
        return null;
    }
  }
  ngOnInit() {
    this.keycloak.getToken().then(token => {
    sessionStorage.setItem('AccessToken', 'Bearer ' + token);
    const tokenInfo = this.getDecodedAccessToken(token);
    // this.getTokenExpireTime(token);
    this.loginKeycloak(tokenInfo, token);
    });

   //setInterval(()=> { this.loginRefresh() }, 170 * 1000);
    console.log("initialise app");
    this.showCaptcha = this.enablecaptch === true ? true : false;
    this.state.loginFlow = 'normal';
  }
  loginRefresh(){
    this.keycloak.updateToken().then(a =>{
      this.keycloak.getToken().then(token => {
      sessionStorage.setItem('AccessToken', 'Bearer ' + token);
      });
    });

  }
  getTokenExpireTime(token){
    const tokenInfo = this.getDecodedAccessToken(token);
    var timeLocal = new Date().getTime();
    let timeSkew = Math.floor(timeLocal / 1000) - tokenInfo.iat;
    var expiresIn = (tokenInfo['exp'] - (new Date().getTime() / 1000) + timeSkew) * 1000;
    this.RefreshTokenInterval = Math.round(expiresIn / 1000) - 30;
    console.log(this.RefreshTokenInterval)
    interval(this.RefreshTokenInterval * 1000).subscribe((func => {
      this.loginRefresh();
      }))
  }
  loginKeycloak(info, token) {
    this.loading = true; // show the spinner
    this.appSvc.loginUserKeycloak().subscribe(
      (res) => {
        sessionStorage.setItem('previleges', JSON.stringify(res));
        this.loading = false;
        sessionStorage.setItem('isUserLogin', 'true');
        sessionStorage.setItem('UserId', res.userId);
        sessionStorage.setItem('Email', info.email);
        sessionStorage.setItem('UserName', res.userfullName);
        sessionStorage.setItem('UserImage', res.userPhoto);
        sessionStorage.setItem('UserRoles', res.roles);
        sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
        const userRolesObject = this.appSvc.getUserPrivilages();
        this.loading = true; // hide the spinner if success
        this.appSvc.GetDefaultProjectId().subscribe(resp => {
          if (resp) {
            this.loading = false;
            sessionStorage.setItem('projectId', resp.item2.projectid);
            sessionStorage.setItem('diagramSymbols', resp.item2.projdiagsymbolname.toLowerCase());
          }

        if (this.order.order === 'popular' && sessionStorage.length > 1 && (userRolesObject.roles === 'Operation SME' || 
        userRolesObject.roles === 'Remote Operator' )) {
          sessionStorage.setItem('substationId', this.order.subId);
          sessionStorage.setItem('isFromLogin', 'false');
          let sub = localStorage.getItem('subNameOPC');
          this.router.navigate(['/operations', 'scenario-execution', sub, this.order.req,]);
        } else if (userRolesObject.MemberAndPriviledge &&
          userRolesObject.roles.toLowerCase() === 'admin') {
          this.router.navigateByUrl('/dashboard/members-privileges');
        } else if (userRolesObject.roles === 'Quality SME') {
          this.router.navigateByUrl('/execution/landing/qualitySME');
        } else if (userRolesObject.roles === 'Execution Team Lead' || userRolesObject.roles === 'Execution Lead') {
          this.router.navigateByUrl('/execution/landing/teamLead');
        } else if (userRolesObject.roles === 'Execution Owner') {
          this.router.navigateByUrl('/execution/landing/ExecutionOwner');
        } else if (userRolesObject.roles === UserRole.ProjectManager  || userRolesObject.roles === 'Project Support'
           || userRolesObject.roles === 'Project Manager (C)') {
          this.router.navigateByUrl('/dashboard/reliability-center');
        } else if (userRolesObject.roles === UserRole.PolicySME) {
          this.router.navigateByUrl('/policy');
        } else if (userRolesObject.roles === 'Maintenance Manager') {
          this.router.navigateByUrl('/planning/manager');
        } else if (userRolesObject.roles === 'Operation SME' || userRolesObject.roles === 'Remote Operator') {
          this.router.navigateByUrl('/operations/view');
        } else if (userRolesObject.roles === 'Planner') {
          this.router.navigateByUrl('/planning/planner');
        } else if (userRolesObject.IsSystemRole === 'false' || userRolesObject.roles === UserRole.PolicySME) {
          this.router.navigateByUrl('/dashboard/project-settings');
        } else if (userRolesObject.roles === 'System Manager' || userRolesObject.roles === 'System Support') {
          this.router.navigateByUrl('/dashboard');
        } else {
          this.router.navigateByUrl('/dashboard');
        }
      });
      }, (error) => {
        this.loading = false;
      });
  }
  forgotPassword() {
    // this.modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    // this.modalRef.componentInstance.message = 'Not yet implemented.';

    // TODO uncomment bellow line to implement forgotPassword function.
    this.state.loginFlow = 'recovery';
  }
  validateForm() {
    return this.state.form['email'];
  }
  sumitForgotPassword() {
    const q = document.getElementById('error');

    if (!this.validateForm()) {
      return;
    }
    if (this.validateForm()) {
      if (this.state.form.email && q.className === 'error-indicate') {
        // this.router.navigateByUrl('/changePassword');
        this.loading = true; // show the spinner
        // this.router.navigateByUrl('/changePassword/14fa994e-685c-4bba-b606-6c9c1611be54');
        this.appSvc.forgotPassword(this.state.form.email).subscribe({next:result => {
          if (result.statusCode === 200) {
            this.state.loginFlow = 'emailSent';
            this.emailSentMessage = result.statusDescription;
          }
          this.loading = false;
        }, error:() => {
          this.loading = false;
        }});
      }
    }
  }
  onBlurMethod(event) {
    this.showError = true;
    this.passwordError = false;
  }
  onBlurEmail(event) {
    this.showEmailError = true;
  }
  login() {
    this.loginErrorMessage = null;

    if (!this.state.form.username || !this.state.form.password) {
      this.resetCaptcha = true;
      return this.loginErrorMessage = 'Please enter the username and password';
    }
    if (this.enablecaptch === true && (this.captchaToken === null || this.captchaToken === undefined)) {
      this.CaptchanotVerified = true;
      return this.capchaError = 'please verify the captcha';
    }
    // var publickey="MjA0OCE8UlNBS2V5VmFsdWU+PE1vZHVsdXM+djFTTVVyYk5SZW50VDEya0FhWXNRMEh3Y2hjWG9nbnFUWGpYd1NXaGR5Qi9aaTQ5VnF4L0lFdWxSaGFhVjdHOUtENWRmY0I4eEZaZGgyNGJ0MHpZbGFNTlFyRVBNNnQzUEdvZXZmMXVCby9wVnhlcWFocEFkWkIwelNJcjhwTk5UOW52czV5WEN1Q00xRFo0UUR3Q3A3b2U2aXc2ZHZ4VEZNWFZJdW9rSkcrdmlFMWhORDhnbGg0dFVsMWVBdThKT3YyR0tyWmhvTmUxK2tnRzNNUmRueEFGTDQyRDl4eWF5NERvcmpGL2ZjYWNNc3dFYkM3MUo2bFNobnR2YnQ1RnY0elY1bkg0aDhqYzhnV1dQVDUvWG16TElLMmlJRDJ6L3NyeGgvbzdMRkRhWVhXMnVwbUt5VUJQR2k0OGJLUVZKT3JjZU9rd3owWE1nTDFJUk4yWnhRPT08L01vZHVsdXM+PEV4cG9uZW50PkFRQUI8L0V4cG9uZW50PjwvUlNBS2V5VmFsdWU+";
    var publickey = 'key';
    var encrptpassword = this.encrDecrService.set(publickey, this.state.form.password);
    const requestData = {
      'userName': this.state.form.username,
      'Password': encrptpassword,
      // 'CaptchaResponse': this.captchaToken
      'CaptchaResponse': settings.enableCaptcha === true ? this.captchaToken : null,


      //'Password': this.state.form.password
    };

    this.loading = true; // show the spinner
    this.appSvc.loginUser(requestData).subscribe({next:
      (res) => {
        this.resetCaptcha = false;
        this.loading = false;
        const decodedToken = Jwthelper.decodeToken(res.access_token);
        const roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        const email = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
        sessionStorage.setItem('isUserLogin','true');
        sessionStorage.setItem('AccessToken', 'Bearer ' + res.access_token);
        sessionStorage.setItem('RefershToken', res.refresh_token);
        sessionStorage.setItem('UserId', res.userId);
        sessionStorage.setItem('Email', email);
        sessionStorage.setItem('UserName', res.userfullName);
        sessionStorage.setItem('UserImage', res.userPhoto);
        sessionStorage.setItem('UserRoles', roles);
        sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
        const userRolesObject = this.appSvc.getUserPrivilages();
        // this.userRoles = roles;
        this.loading = true; // hide the spinner if success
        this.appSvc.GetDefaultProjectId().subscribe(resp => {
          if (resp) {
            this.loading = false;
            sessionStorage.setItem('projectId', resp.item2.projectid);
          }
        });
        if (userRolesObject.MemberAndPriviledge &&
          userRolesObject.roles.toLowerCase() === 'admin') {
          this.isView = false;
          this.router.navigateByUrl('/dashboard/members-privileges');
        } else if (userRolesObject.roles === 'Quality SME') {
          this.router.navigateByUrl('/execution/landing/qualitySME');
        } else if (userRolesObject.roles === 'Execution Team Lead' || userRolesObject.roles === 'Execution Lead') {
          this.router.navigateByUrl('/execution/landing/teamLead');
        } else if (userRolesObject.roles === 'Execution Owner') {
          this.router.navigateByUrl('/execution/landing/ExecutionOwner');
        } else if (userRolesObject.roles === UserRole.ProjectManager  || userRolesObject.roles === 'Project Support'
           || userRolesObject.roles === 'Project Manager (C)') {
          this.router.navigateByUrl('/dashboard/reliability-center');
        } else if (userRolesObject.roles === UserRole.PolicySME) {
          this.router.navigateByUrl('/policy');
        } else if (userRolesObject.roles === 'Maintenance Manager') {
          this.router.navigateByUrl('/planning/manager');
        } else if (userRolesObject.roles === 'Operation SME' || userRolesObject.roles === 'Remote Operator') {
          this.router.navigateByUrl('/operations/view');
        } else if (userRolesObject.roles === 'Planner') {
          this.router.navigateByUrl('/planning/planner');
        } else if (userRolesObject.IsSystemRole === 'false' || userRolesObject.roles === UserRole.PolicySME) {
          // this.router.navigateByUrl('/dashboard/reliability-center');
          this.router.navigateByUrl('/dashboard/project-settings');
        } else if (userRolesObject.roles === 'System Manager' || userRolesObject.roles === 'System Support') {
          this.router.navigateByUrl('/dashboard');
        } else {
          this.router.navigateByUrl('/dashboard');
        }
        // }
        // });
      }, error:(error) => {
        if (error) {
          this.loading = false;
          this.passwordError = true;
          this.showError = false;
          this.resetCaptcha = true;
             if (error.status === 403 && error.error.statusCode === 401) {
            const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true, windowClass: 'mdModal' });
            modalRef.componentInstance.title = 'Message';
            modalRef.componentInstance.message = error.error.errorMessage;
            this.router.navigate(['/passwordchange']);
            // modalRef.result.then(ret => {
            //   if (ret === 'ok') {
            //     this.router.navigate(['/passwordchange']);
            //   }
            // });
          }

          else if (error.error.statusCode === 403) {
            const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true, windowClass: 'mdModal' });
            modalRef.componentInstance.title = 'Message';
            modalRef.componentInstance.message = error.error.errorMessage;

            this.router.navigate(['/forgotPassword']);
            // modalRef.result.then(ret => {
            //   if (ret === 'ok') {
            //     this.router.navigate(['/passwordchange']);
            //   }
            // });
          }

          // else   if (error.status === 401) {
          //   const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true, windowClass: 'mdModal' });
          //   modalRef.componentInstance.message = "error.error.errorMessage";
          //   this.router.navigate(['/passwordchange']);
          //   // modalRef.result.then(ret => {
          //   //   if (ret === 'ok') {
          //   //     this.router.navigate(['/passwordchange']);
          //   //   }
          //   // });
          // }
        }
      }}
    );
    // this.authService
    //   .login(this.state.username, this.state.password)
    //   .subscribe(
    //     user => {
    //       this.state.user = user;
    //       this.state.loginFlow = 'language-selection';
    //     },
    //     err => {
    //       this.loginErrorMessage = 'Invalid username or password';
    //     }
    //   );
  }

  OPCClientandPfModelLogin() {
    const requestData = {
      'userName': this.state.form.username,
      'Password': this.state.form.password,
      'CaptchaResponse': 'string',
    };
    this.appSvc.OPCClientandPfModelLogin(requestData).subscribe( res => {
const decodedToken = Jwthelper.decodeToken(res.access_token);
const roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
const email = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
sessionStorage.setItem('AccessToken', 'Bearer ' + res.access_token);
sessionStorage.setItem('RefershToken', res.refresh_token);
sessionStorage.setItem('UserId', res.userId);
sessionStorage.setItem('Email', email);
sessionStorage.setItem('UserName', res.userfullName);
sessionStorage.setItem('UserImage', res.userPhoto);
sessionStorage.setItem('UserRoles', roles);
//const userRolesObject = this.appSvc.getUserPrivilages();
this.router.navigateByUrl('/dashboard/reliability-center');
    });
  }

  next() {
    this.router.navigateByUrl('/dashboard/members-privileges');
  }

  isEmail(str) {
    const regex = /^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$/;
    return regex.test(str);
  }

  public resolved(captchaResponse: string) {
    this.captchaToken = captchaResponse;
    this.CaptchanotVerified = false;
    this.resetCaptcha = false;
  }

}
