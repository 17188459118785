<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Alarm notification settings</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="filterForm">
      <div class="row">
        <div class="form-group col-md-3">
          <label>Substation</label>
          <select formControlName="substation" class="select-ctrl">
            <option value="All">All</option>
            <option *ngFor="let dict of substationDict" value="{{dict}}">{{dict}}</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label>Equipment category</label>
          <select formControlName="eqptCat" class="select-ctrl">
            <option value="All">All</option>
            <option *ngFor="let dict of eqptCatDict" value="{{dict}}">{{dict}}</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label>Equipment</label>
          <select formControlName="equipment" class="select-ctrl">
            <option value="All">All</option>
            <option *ngFor="let dict of equipmentDict" value="{{dict}}">{{dict}}</option>
          </select>
        </div>
        <div class="form-group col-md-3 grid-display">
          <label>Asset</label>
          <select formControlName="asset" class="select-ctrl">
            <option value="All">All</option>
            <option *ngFor="let dict of assetDict" value="{{dict}}">{{dict}}</option>
          </select>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <input placeholder="Type to search" type="text" class="form-control" formControlName="search">
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-lg-12">
        <app-generic-table [configTableModel]="configTable" [items]="alarmsConfig?.items"></app-generic-table>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-expansion-panel class="notify-me-section" (opened)="_panelOpenState = true"
          (closed)="_panelOpenState = false">
          <mat-expansion-panel-header class="notify-me-section-header">
            <mat-panel-title>
              <p class="notify-me-section-header-text">Notify me through</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="notify-me-section-content">
            <form [formGroup]="notifyForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="notification-option">
                    <label class="label" for="email">Email</label>
                    <label class="checkbox-switch float-right"
                      [ngClass]="{'checkbox-switch-checked': notifyForm.value.notifyEmail}">
                      <input type="checkbox" formControlName="notifyEmail">
                      <i class="icon-radio" aria-hidden="true"
                        [ngClass]="{'icon-radio-checked': notifyForm.value.notifyEmail}"></i>
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="notification-option">
                    <label class="label" for="sms">SMS</label>
                    <label class="checkbox-switch float-right"
                      [ngClass]="{'checkbox-switch-checked': notifyForm.value.notifySms}">
                      <input type="checkbox" formControlName="notifySms">
                      <i class="icon-radio" aria-hidden="true"
                        [ngClass]="{'icon-radio-checked': notifyForm.value.notifySms}"></i>
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit()">Save</button>
  </div>

  <ng-template #toggleSwitch let-item="item">
    <label class="checkbox-switch"
      [ngClass]="{'checkbox-switch-disabled': item.severity === 'High', 'checkbox-switch-checked': item.notify || item.severity === 'High'}">
      <input type="checkbox" [(ngModel)]="item.notify" [disabled]="item.severity === 'High'">
      <i class="icon-radio" aria-hidden="true"
        [ngClass]="{'icon-radio-checked': item.notify || item.severity === 'High'}"></i>
    </label>
  </ng-template>
</div>