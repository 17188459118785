<div class="page-wrapper">
  <header class="header-container">
    <h3 i18n>{{title}}</h3>
  </header>
  <div class="loading-box" *ngIf="loading">
    <div class="img" alt="loading"></div>
  </div>
  <div class="table_border">
    <div class="table-wrapper p-2" style="background-color: white;">
      <div class="freeze-table">
        <div class="wrapper" [perfectScrollbar]="{}">
          <ng-container [ngTemplateOutlet]="table"></ng-container>
        </div>
        <div class="fixed-wrapper">
          <ng-container [ngTemplateOutlet]="table"></ng-container>
        </div>
      </div>
      <ng-template #table>
        <table class="base-table" aria-hidden="true">


          <tr>
            <th id="idUserName">User Name</th>

            <th id="idVersionName">Version Name</th>
            <th id="idSubstationName">Substation Name</th>
            <th id="idExcelRequest">Excel Request Time</th>
            <th id="idExcelComplete">Excel Complete Time</th>

            <th id="idStatus22">Status</th>
           
            <th id="idSpaces22">
              &nbsp;
            </th>
          </tr>
          <tr *ngFor="let item of filterColumns">

            <td>{{item.userName}}</td>

            <td>{{item.versionName}}</td>
            <td>{{item.substationName}}</td>
            <td>{{item.excelRequrestTs | date:'medium'}}</td>
            <td>{{item.excelCompleteTs | date:'medium'}}</td>
            <td *ngIf="item.isError == false">Completed</td>
            <td *ngIf="item.isError === true">Error</td>

            <td>
              <a *ngIf="showDownload" (click)="openDownloadModal(item)" href="javascript:;"><i
                  class="icon icon-table-download" aria-hidden="true"></i></a>
            </td>



          </tr>




        </table>
      </ng-template>
    </div>
  </div>
</div>