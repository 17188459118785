import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TippyDirective } from './cursortooltip.directive';
import { NumberOnlyDirective } from './number.directive';
import { NgTemplateOutlet } from './ng_template_outlet';

@NgModule({
  declarations: [
    NumberOnlyDirective,
    TippyDirective,
    NgTemplateOutlet,
  ],
  exports: [
    NumberOnlyDirective,
    TippyDirective,
    NgTemplateOutlet,
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule {
}
