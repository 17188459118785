import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import { ApplicationService } from '../../services/application.service';
import { tap } from 'rxjs/operators';
import { jwt_decode } from 'jwt-decode';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AppAuthGuard extends KeycloakAuthGuard {

  constructor(protected readonly router: Router,
              protected readonly keycloak: KeycloakService,
              protected appSvc: ApplicationService) {
    super(router, keycloak);
  }

  public showLoader$ = new Subject<boolean>();
  getDecodedAccessToken(token: string): any {
    try {
        return jwt_decode(token);
    }  catch (Error) {
        return null;
    }
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.showLoader$.next(true);

    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    if (!sessionStorage.getItem('UserRoles') && state.url !== '/login') {
      let tokenInfo;

      try {
        await this.keycloak.getToken().then(token => {
          sessionStorage.setItem('AccessToken', 'Bearer ' + token);
          tokenInfo = this.getDecodedAccessToken(token);
        });

        await this.appSvc.loginUserKeycloak().pipe(tap(
          (res: any) => {
            sessionStorage.setItem('isFromLogin', 'true');
            sessionStorage.setItem('previleges', JSON.stringify(res));
            sessionStorage.setItem('isUserLogin', 'true');
            sessionStorage.setItem('UserId', res.userId);
            sessionStorage.setItem('Email', tokenInfo && tokenInfo.email);
            sessionStorage.setItem('UserName', res.userfullName);
            sessionStorage.setItem('UserImage', res.userPhoto);
            sessionStorage.setItem('UserRoles', res.roles);
            sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
            //const userRolesObject = this.appSvc.getUserPrivilages();
            // this.loading = true; // hide the spinner if success
          })).toPromise();

        await this.appSvc.GetDefaultProjectId().pipe(tap(resp => {
          if (resp) {
            sessionStorage.setItem('projectId', resp.item2.projectid);
            sessionStorage.setItem('diagramSymbols', resp.item2.projdiagsymbolname.toLowerCase());
          }
        })).toPromise();

      } catch(error) {
        this.showLoader$.next(false);
        return false;
      }

      location.reload();
    }

    this.showLoader$.next(false);

    let granted = true;
    return granted;
  }

}
