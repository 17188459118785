export class Settings {
    managesystemApiURL: string;
    manageProjectApiURL: string;
    SLDApiURL: string;
    exaURL: string;
    loadDataApiURL:string;
    performanceModelPropertyApiURL:string;
    performanceModelSubsystemApiURL:string;
    KeycloakAuthority: string;
    KeycloakRealm: string;
    KeycloakClient: string;
    KeycloakSecret: string;
    identiqApiURL: string;
    enableIdentiqConnection: string;
    reportEnabled: string;
    CommonAPIUrl: string;
}
