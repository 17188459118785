import { Directive, ElementRef, Input, OnChanges, OnDestroy } from '@angular/core';
import tippy, { Instance } from 'tippy.js';


/**
 * Cursor following tooltip directive
 */
@Directive({
  /* tslint:disable-next-line */
  selector: '[cursorTooltip]'
})
export class TippyDirective implements OnChanges, OnDestroy {
  tippyInstance: Instance;
  defaultOptions = {
    distance: 22,
  };
  @Input() public tooltipOptions: any;

  constructor(private el: ElementRef) {
    this.el = el;
  }
  public ngOnChanges() {
    if (this.tooltipOptions.content) {
      if (this.tippyInstance) {
        this.tippyInstance.setContent(this.tooltipOptions.content);
      } else {
        if (this.tooltipOptions.content) {
          this.tippyInstance = tippy(this.el.nativeElement, { ...this.defaultOptions, ...this.tooltipOptions } || {}) as Instance;
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.tippyInstance) {
      this.tippyInstance.destroy();
    }
  }

}
