import { AlarmNotificationSettingsComponent } from './modal/alarm-notification-settings/alarm-notification-settings.component';
import { Location } from '@angular/common';
import { Component, DoCheck, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { fromEvent, merge, of } from 'rxjs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { finalize, map} from 'rxjs/operators';
import { ApplicationService } from '../../../services/application.service';
import { AuthService } from '../../../services/auth.service';
import { NotificationService } from '../../../services/notification.service';
import { AlarmService } from '../../../services/alarm.service';
import { UserTypeResolverService } from '../../../services/user-type-resolver.service';
import { DashboardService } from '../../containers/dashboard/dashboard.service';
import { MainHeaderService } from './main-header.service';
import { LogoutConfirmationModalComponent } from './modal/logout-confirmation-modal/logout-confirmation-modal.component';
import { NotificationSettingsComponent } from './modal/notification-settings/notification-settings.component';
import { SystemNotificationsComponent } from './modal/system-notifications/system-notifications.component';
import { PopUpService } from 'src/services/popUp.service';
const Jwthelper = new JwtHelperService();
import { KeycloakService } from 'keycloak-angular';
import { UserRole } from 'src/app/models/userrole';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  providers: [NgbActiveModal]
})
export class MainHeaderComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('role', { static: false }) role: ElementRef;
  @ViewChild('version', { static: false }) version: ElementRef;
  @ViewChild('navbar', { static: false }) navbar: ElementRef;
  @ViewChild('hamburger', { static: false }) hamburger: ElementRef;
  @ViewChild('systemWarn', { static: false }) systemWarn: ElementRef;
  @ViewChild('systemDetail', { static: false }) systemDetail: ElementRef;

  public db: any = {};
  public userType: string;
  public RefershTokenCalled: any;
  public project: any;
  userName: string;
  buildVersionNumber: string;
  buildVersionNumberError: boolean;
  userImage;
  showDropdown = false;
  loading = false;
  public topMenu = [];
  public bottomMenu = [];
  login: boolean;
  public highlightNotification = null;
  public systemNotifications = { relCareConnectionError: false, scadaGlobalError: false, scadaAssetErrors: [] };
  versionList = [];
  isRefreshToken: boolean = false;
  userRole = '';
  roleType = '';
  custName = '';
  projName = '';
  @Input() showExtraInfo: boolean;
  display: string;
  rolePopup = false;
  versionPopup = false;
  public openSide = false;
  selectedRole: any = {};
  selectedVersion: any = {};
  filterRoles: any = [];
  hideClientProjectDetail = false;
  ishide = false;
  public metadataInfo: { title: string, clientName: string, projectName: string, kernelVersion: string };
  roles: any = [
    { 'name': UserRole.ProjectManager, 'role': 'PM' },
    { 'name': UserRole.ProjectManager_C, 'role': 'Project Manager (C)', 'active': false },
    { 'name': UserRole.ProjectSupport, 'role': 'Project Support' },
    { 'name': UserRole.QualitySME, 'role': 'Quality SME' },
    { 'name': UserRole.PolicySME, 'role': UserRole.PolicySME },
    { 'name': UserRole.OperationSME, 'role': 'Operation SME' },
    { 'name': UserRole.RemoteOperator, 'role': 'Remote Operator' },
    { 'name': UserRole.Planner, 'role': 'PLANNER', 'active': false },
    { 'name': UserRole.ExecutionTeamLead, 'role': 'Execution Team Lead' },
    { 'name': UserRole.MaintenanceManager, 'role': 'MNT_MANAGER', 'active': false },
    { 'name': UserRole.ExecutionOwner, 'role': 'Execution Owner' },
    { 'name': UserRole.SystemManager, 'role': 'System Manager' },
    { 'name': UserRole.SystemSupport, 'role': 'System Support' },

  ];
  defaultPageSize = 7;
  page = 0;
  alarmPage = 0;

  isOpen = false;
  isAlarmOpen = false;
  routerSub = null;
  online$: Observable<boolean>;
  showServerOfflineError: boolean = false;
  notificationPageSize = 10;
  alarmPageSize = 7;
  isRole: boolean;
  currentRole: any;
  roleselected: any;
  errorrole: any;
  alarmList : any = [];

  constructor(
    public appSvc: ApplicationService, private router: Router, private userTypeService: UserTypeResolverService,
    private authService: AuthService,
    private mainheader: MainHeaderService,
    private dashboard: DashboardService,
    private loc: Location,
    private notificationService: NotificationService,
    private alarmService: AlarmService,
    public activeModal: NgbActiveModal, private modalService: NgbModal, config: NgbModalConfig,
    private popupService: PopUpService,private keycloak:KeycloakService
  ) {
    config.backdrop = 'static';
  }

  isMarkAllRead() {
    return !this.appSvc.notifications.some(x => !x.isRead);
  }

  isAllAcknowledged() {
    return !this.appSvc.allAlarms.some(x => !x.isAcknowledged);
  }

  changeRole(item) {
    this.modalService.dismissAll();
   this.isAlarmOpen = false;
    this.popupService.popup.next('close');
    this.selectedRole = item;
    this.openSide = false;
    this.rolePopup = false;
    this.roleType = item.name;
    this.appSvc.setEquipment("00000000-0000-0000-0000-000000000000");
    this.appSvc.setSubstation("00000000-0000-0000-0000-000000000000");
    this.appSvc.setRegionId("00000000-0000-0000-0000-000000000000");
    this.appSvc.setBayId("00000000-0000-0000-0000-000000000000");
    this.appSvc.setPlannerPlanId('00000000-0000-0000-0000-000000000000');
    this.appSvc.setPlanId("00000000-0000-0000-0000-000000000000");
    sessionStorage.setItem('currentRole', item.name);
    sessionStorage.setItem('currentRoleSelected', item.name);
    this.dashboard.changeRole(this.roleType);
    if (this.roleType === 'System Manager' || this.roleType === 'Admin') {
      this.hideClientProjectDetail = true;
    } else {
      this.hideClientProjectDetail = false;
    }
    if (item.role === 'PLANNER') {
      this.router.navigate(['/planning/planner']);
    } else if (item.role === 'MNT_MANAGER') {
      this.router.navigate(['/planning/manager']);
    } else if (item.role === 'PM') {
      this.router.navigate(['/dashboard/reliability-center']);
    } else if (item.role === 'Project Support') {
      this.router.navigate(['/dashboard/reliability-center']);
    } else if (item.role === 'Project Manager (C)') {
      this.router.navigate(['/dashboard/reliability-center']);
    } else if (item.role === UserRole.PolicySME) {
      this.router.navigate(['/policy']);
    } else if (item.role === 'Operation SME' || item.role === 'Remote Operator') {
      this.router.navigate(['/operations', 'view']);
    } else if (item.role === 'Quality SME') {
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/execution/landing/qualitySME']);
      // );
    } else if (item.role === 'Execution Team Lead') {
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/execution/landing/teamLead']);
      // );
    } else if (item.role === 'Execution Owner') {
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl('/execution/landing/ExecutionOwner');
      // );
    } else if (item.role === 'System Manager' || item.role === 'System Support') {
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>

      this.router.navigateByUrl('/dashboard');
      // );
    }
    this.isRole = item.name === 'Project Support' || item.name === UserRole.ProjectManager ||
      item.name === 'Project Manager (C)' ? true : false;
  }

  changeVersion(item) {
    this.selectedVersion = item;
    sessionStorage.setItem('versionId', this.selectedVersion.id);
    sessionStorage.setItem('versionName', this.selectedVersion.name);
    this.openSide = false;
    this.versionPopup = false;
    if ((this.loc.path() === '/dashboard/kernel-parametrisation') || (this.loc.path().includes('edit-equipment'))) {
      this.redirectTo('/dashboard/kernel-parametrisation');
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  showAlarms(): boolean {
    let currentRole = sessionStorage.getItem("currentRoleSelected");
    if (currentRole ===null || currentRole === ''){
      currentRole = sessionStorage.getItem("currentRole");
    }

    if (!currentRole)
      return false;
    let isRemoteOperator = !!["Operation SME", "Remote Operator"].find(x => x == currentRole);
    return isRemoteOperator;
  }

  ngOnInit() {
    this.appSvc.setUserLogin(true);
    this.errorrole = localStorage.getItem('errorrole');
    // sessionStorage.setItem('isUserLogin','true');
    this.login = true;
    this.appSvc.getCurrentBuildVersion().subscribe({next:  (resp) => {
      this.buildVersionNumberError = false;
      this.buildVersionNumber = resp.returnObj;
    }, error : (err) => {
      console.log("currentBuildVersion : Failed" + err);
      if (err != null && err.error != null && err.error.text != null) {
        this.buildVersionNumberError = true;
        this.buildVersionNumber = err.error.text;
      }
    }
   });
    if (sessionStorage.length === 1) {
      this.ishide = true;
      // this.appSvc.GetDefaultCustomerDetails().subscribe(data => {
      //   if (data.returnObj) {
      //     this.custName = data.returnObj.custname;
      //     this.projName = data.returnObj.projectname;
      //   }
      // }, err => {
      // });
      // this.appSvc.getApplicationMetadata().subscribe((resp) => this.metadataInfo = resp);
      // this.userType = this.errorrole;
      // this.selectedRole = _.find(this.roles, { role: this.userType });
      // this.selectedVersion.name = localStorage.getItem('versionName');
      // console.log("done");
    }
    if (sessionStorage.length > 1) {
      this.ishide = false;
      this.isRefreshToken = (sessionStorage.getItem('RefershToken') != null) ? true : false;
      this.mainheader.navItem$.subscribe({next: (res) => {
        this.appSvc.GetAllKernelVersions().subscribe(version => {
          this.versionList = version;
          this.selectedVersion = version.slice(-1).pop();
          sessionStorage.setItem('versionId', this.selectedVersion.id);
          sessionStorage.setItem('versionName', this.selectedVersion.name);
          localStorage.setItem('versionName', this.selectedVersion.name);
        });
      }, error: () => {}
      });

      this.appSvc.GetDefaultCustomerDetails().subscribe({next:  (data) => {
        if (data.returnObj) {
          this.custName = data.returnObj.custname;
          this.projName = data.returnObj.projectname;
        }
      }, error: () => {}
    });
    this.routerSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
        .subscribe((value) => {
          this.getNotifications();
          this.getAlarms();
        });

      this.getNotifications();
      this.getAlarms();

      this.online$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(map(()=> true)),
        fromEvent(window, 'offline').pipe(map(()=>false))
      );
      this.roleType = sessionStorage.getItem('UserRoles');
      const roleTypeArr = this.roleType?.split(',');
      //const a = typeof (this.roleType);

      this.roleType = this.roleType.includes('System Manager') ? 'System Manager' : this.roleType;
      this.roleType = this.roleType.includes('Admin') ? 'Admin' : this.roleType;
      sessionStorage.setItem('currentRole', this.roleType);
      if (roleTypeArr.length > 1) {
        sessionStorage.setItem('currentRole', roleTypeArr[0]);
      }
      this.isRole = roleTypeArr[0] === 'Project Support' || roleTypeArr[0] === UserRole.ProjectManager ||
        roleTypeArr[0] === 'Project Manager (C)' ? true : false;
      this.dashboard.changeRole(this.roleType);
      if (this.roleType === 'System Manager' || this.roleType === 'Admin') {
        this.hideClientProjectDetail = true;
      } else {
        this.hideClientProjectDetail = false;
      }
      // this.appSvc.GetAllKernelVersions().subscribe(version => {
      //   this.versionList = version;
      //   this.selectedVersion = version.slice(-1).pop();
      //   sessionStorage.setItem('versionId', this.selectedVersion.id);
      //   sessionStorage.setItem('versionName', this.selectedVersion.name);
      // });
      this.appSvc.getApplicationMetadata().subscribe((resp) => this.metadataInfo = resp);
      this.userType = this.userTypeService.resolve();
      this.userType = sessionStorage.getItem('currentRoleSelected');
      if (this.userType === null || this.userType === undefined) {
        this.userType = this.appSvc.getUserPrivilages().roles;
      }
      if (this.userType === 'Planner') {
        this.selectedRole = _.find(this.roles, { role: 'PLANNER' });
      } else if (this.userType === 'Maintenance Manager') {
        this.selectedRole = _.find(this.roles, { role: 'MNT_MANAGER' });
      } else if (this.userType === 'Execution Team Lead') {
        this.selectedRole = _.find(this.roles, { role: 'Execution Team Lead' });
      } else if (this.userType === 'Execution Owner') {
        this.selectedRole = _.find(this.roles, { role: 'Execution Owner' });
      } else if (this.userType === 'Remote Operator') {
        this.selectedRole = _.find(this.roles, { role: 'Remote Operator' });
      } else if (this.userType === 'Operation SME') {
        this.selectedRole = _.find(this.roles, { role: 'Operation SME' });
      } else if (this.userType === 'System Manager') {
        this.selectedRole = _.find(this.roles, { role: 'System Manager' });
      } else if (this.userType === 'System Support') {
        this.selectedRole = _.find(this.roles, { role: 'System Support' });
      } else if (this.userType === 'Project Support') {
        this.selectedRole = _.find(this.roles, { role: 'Project Support' });
      } else if (this.userType === 'Project Manager (C)') {
        this.selectedRole = _.find(this.roles, { role: 'Project Manager (C)' });
      } else if (this.userType === 'Quality SME') {
        this.selectedRole = _.find(this.roles, { role: 'Quality SME' });
      } else if (this.userType === UserRole.PolicySME) {
        this.selectedRole = _.find(this.roles, { role: UserRole.PolicySME });
      } else {
        this.selectedRole = _.find(this.roles, { role: 'PM' });
      }

      if ((this.userType === UserRole.ProjectManager) || (this.userType === 'Execution Team Lead') || (this.userType === 'Execution Owner')) {
        // this.appSvc.getAllProjects().subscribe(res => {
        //   this.project = res.returnObj.filter(project => project.projectId === this.authService.getUser().projectId)[0];
        // });
      }

      if (this.userType === 'Execution Team Lead') {
        this.userRole = 'Execution Team Lead';
      }
      if (this.userType === 'Execution Owner') {
        this.userRole = 'Execution Owner';
      }
      this.userName = sessionStorage.getItem('UserName');
      this.userImage = sessionStorage.getItem('UserImage');
      //const getToken = sessionStorage.getItem('AccessToken');
      const token = JSON.parse(sessionStorage.getItem('previleges'));
      const roles = token.roles;
      // const decodedToken = Jwthelper.decodeToken(getToken);
      // const roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (Array.isArray(roles)) {
        roles.forEach(element => {
          this.roles.filter(role => {
            if (role.name === element) {
              this.filterRoles.push(role);
            }
          });
        });
      } else {
        this.roles.filter(role => {
          if (role.name === roles) {
            this.filterRoles.push(role);
          }
        });
      }

      this.roles = this.filterRoles;
      //this.changeRole(this.selectedRole);
    }
    // this.appSvc.get()
    // .subscribe(res => {
    //   this.db = res;
    // });

    // this.userType = this.userTypeService.resolve();
    // if (this.userType === 'PM') {
    //   this.appSvc.getAllProjects().subscribe(res => {
    //     this.project = res.filter(project => project.projectId === this.authService.getUser().projectId)[0];
    //   });
    // }
  }

  ngOnDestroy() {
    if (this.routerSub !== null) {
      this.routerSub.unsubscribe();
    }
    if (this.appSvc.subscription != undefined) {
      this.appSvc.subscription.unsubscribe();
    }
  }

  getAlarms() {
    if (sessionStorage.length > 1 && (this.selectedRole.name == 'Operation SME' || this.selectedRole.name == 'Remote Operator')) {
      sessionStorage.getItem('RefershToken')

      this.alarmService.getAlarms().subscribe({next:  res => {
        let temp = _.cloneDeep(res.items);
        temp = _.orderBy(temp, 'createdAt', 'desc');
        const list = _.map(temp, item => {
          const data = _.cloneDeep(item);
          let createdAtLocal = moment.utc(data.createdAt).local();
          data.createdAt = createdAtLocal.fromNow();
          return data;
        });
        this.appSvc.allAlarms = list;
        this.appSvc.alarms = list.slice(0, this.alarmPageSize - 1);
        this.alarmList = list.slice(0, this.alarmPageSize - 1);
        this.loading = false;

      }
      });
    }
  }

  getNotifications() {
    if (sessionStorage.length > 1) {
      sessionStorage.getItem('RefershToken')
      this.notificationService.getNotifications().subscribe(res => {
        let temp = _.cloneDeep(res);
        temp = _.orderBy(temp, 'createdAt', 'desc');
        const list = _.map(temp, item => {
          const data = _.cloneDeep(item);
          let createdAtLocal = moment.utc(data.createdAt).local();
          data.createdAt = createdAtLocal.fromNow();
          return data;
        });
        this.appSvc.allNotifications = list;
        this.appSvc.notifications = list.slice(0, this.defaultPageSize - 1);
        this.loading = false;

        if (this.appSvc.notificationId) {
          let notificationId = this.appSvc.notificationId;
          let notification = res.find(x => x.notificationIds.includes(notificationId));
          if (notification && notification.subType == 'Substation risks') {
            for (let task of notification.substationIds)
              task.isRead = false;
            this.appSvc.notificationId = null;
          }
          if (notification && notification.subType == 'Asset risks') {
            for (let task of notification.assetIds)
              task.isRead = false;
            this.appSvc.notificationId = null;
          }
        }
      });

      // this.appSvc.subscription = Observable.interval(60000).subscribe(
      //   val => {
      //     this.RefershTokenCalled = sessionStorage.getItem('RefershTokenCalled');
      //     if ((sessionStorage.getItem('isUserLogin') == "true" && !(this.RefershTokenCalled == "true"))) {
      //       this.displayNotificationMessage();
      //     }
      //   }
      // );
    }
  }

  displayNotificationMessage() {
    /// commenting this call until the system goes live.
    this.notificationService.getSystemNotifications().subscribe({
      next:  (value) => {
        this.showServerOfflineError = false;
      },
      error: (err) => {
        if (err.status === 0) {
          this.showServerOfflineError = true;
        }
      }}
    );
  }
  logOut() {
    this.modalService.open(LogoutConfirmationModalComponent, { centered: true }).result.then(ret => {
      if (ret === 'Logout') {
        this.appSvc.logOut().subscribe({next:
          (res) => {
            this.loading = false;
            sessionStorage.setItem('isUserLogin', 'false');
            this.modalService.dismissAll();
            sessionStorage.clear();
            //localStorage.clear();
            this.appSvc.setUserLogin(false);
            this.login = false;
            if (this.appSvc.subscription != undefined) {
              this.appSvc.subscription.unsubscribe();
            }
            this.showDropdown = true;
            this.appSvc.setPlanId('00000000-0000-0000-0000-000000000000');
            this.appSvc.setPlannerPlanId('00000000-0000-0000-0000-000000000000');
            this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());
          }, error: () => {
            this.loading = false;
          }});
        // this.appSvc.logOut().subscribe(
        //   (res) => {
        //     this.loading = false;
        //     this.modalService.dismissAll();
        //     sessionStorage.clear();
        //     //localStorage.clear();
        //     this.router.navigate(['/']);
        //     this.modalService.dismissAll();
        //   }, err => {
        //     this.loading = false;
        //   });
      } else {
        return;
      }
    }, () => {
    });
  }

  ngDoCheck() {
    this.appSvc.notifications = this.appSvc.allNotifications.slice(0, this.page * this.notificationPageSize - 1 + this.defaultPageSize);
    this.appSvc.alarms = this.appSvc.allAlarms.slice(0, this.alarmPage * this.alarmPageSize - 1 + this.defaultPageSize);
  }

  goTo(item) {
    this.popupService.popup.next('close');
    this.currentRole = sessionStorage.getItem('currentRoleSelected');
    if (this.currentRole === null) {
      this.currentRole = sessionStorage.getItem('currentRole');
    }
    this.appSvc.setEquipment("00000000-0000-0000-0000-000000000000");
    this.appSvc.setSubstation("00000000-0000-0000-0000-000000000000");
    this.appSvc.setRegionId("00000000-0000-0000-0000-000000000000");
    this.appSvc.setBayId("00000000-0000-0000-0000-000000000000");
    this.appSvc.setPlanId("00000000-0000-0000-0000-000000000000");
    if (item.url !== '') {
      this.openSide = false;
      if (item.name === 'Home' && this.currentRole === UserRole.PolicySME) {
        this.router.navigate(['/policy']);
      } else if (item.name === 'Home' && this.currentRole === 'Quality SME') {
        this.router.navigate(['/execution/landing/qualitySME']);
      } else if (item.name === 'Home' && this.currentRole === 'Execution Team Lead') {
        this.router.navigate(['/execution/landing/teamLead']);
      } else if (item.name === 'Home' && this.currentRole === 'Execution Owner') {
        this.router.navigate(['/execution/landing/ExecutionOwner']);
      } else if (item.name === 'Home' && this.currentRole === 'Planner') {
        this.router.navigate(['/planning/planner']);
      } else if (item.name === 'Home' && this.currentRole === 'Maintenance Manager') {
        this.router.navigate(['/planning/manager']);
      } else if (item.name === 'Home' && this.currentRole === 'Operation SME') {
        this.router.navigate(['/operations/view']);
      } else if (item.name === 'Home' && this.currentRole === 'Remote Operator') {
        this.router.navigate(['/operations/view']);
      } else {
        this.router.navigate([item.url]);
      }
    }
  }

  openSlideMenu() {
    this.openSide = !this.openSide;
    let roleTypeArr = this.roleType.split(',');
    // if (this.router.url.indexOf('/dashboard/reliability-center') !== -1 ||
    //   this.router.url.indexOf('dashboard/performance-monitoring') !== -1 ) {
    if (roleTypeArr.includes('System Manager') || roleTypeArr.includes('Admin')) {
      this.topMenu = [
        {
          'name': 'Home',
          'icon': 'icon-menu-home',
          'url': '/dashboard',
          'active': this.router.isActive('/dashboard', true)
        }
      ];
      this.bottomMenu = [];
    } else if (this.isRole) {
      this.topMenu = [
        {
          'name': 'Home',
          'icon': 'icon-menu-home',
          'url': '/dashboard/reliability-center',
          'active': this.router.isActive('/dashboard/reliability-center', true)
        },
        {
          'name': 'Information explorer',
          'icon': 'icon-menu-ie',
          'url': '/reliability-center/information-explorer',
          'active': this.router.isActive('/reliability-center/information-explorer', true)
        },
        {
          'name': 'Performance monitoring',
          'icon': 'icon-info-white',
          'url': '/dashboard/performance-monitoring', 'disabled': false,
          'active': this.router.isActive('/dashboard/performance-monitoring-edit', true)
        },
        {
          'name': 'Knowledge bank',
          'icon': 'icon-menu-kb',
          'url': '/reliability-center/knowledge-bank',
          'disabled': false
        }
      ];
      this.bottomMenu = [];
    } else {
      this.topMenu = [
        {
          'name': 'Home',
          'icon': 'icon-menu-home',
          'url': '/dashboard/reliability-center',
          'active': this.router.isActive('/dashboard/reliability-center', true)
        },
        {
          'name': 'Information explorer',
          'icon': 'icon-menu-ie',
          'url': '/reliability-center/information-explorer',
          'active': this.router.isActive('/reliability-center/information-explorer', true)
        },
        {
          'name': 'Knowledge bank',
          'icon': 'icon-menu-kb',
          'url': '/reliability-center/knowledge-bank',
          'disabled': false
        }
      ];
      this.bottomMenu = [];
    }
  }

  clickedInside($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.role.nativeElement.className = 'role-switch';
    // this.version.nativeElement.className = 'version-switch';
    if (this.roleType === 'System Manager') {
      this.version.nativeElement.className = 'version-switch';
    }
  }

  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    if (this.rolePopup) {
      this.highlightNotification = null;
    }
    this.rolePopup = false;
    this.versionPopup = false;
    // check if clicked is HTML element
    if ($event.target instanceof Element || $event.target instanceof HTMLDocument) {
      if (!$event.target.className.includes('bell') &&
        !($event.target.offsetParent && $event.target.offsetParent.className.includes('notification'))) {
        this.isOpen = false;
        this.page = 0;
      }
      if (!$event.target.className.includes('exclamation') &&
        !($event.target.offsetParent && $event.target.offsetParent.className.includes('alarm'))) {
        this.isAlarmOpen = false;
        this.alarmPage = 0;
      }
      if (this.openSide) {
        if (!this.navbar.nativeElement.contains($event.target) && !this.hamburger.nativeElement.contains($event.target)) {
          this.openSlideMenu();
        }
      }
      // if d3 created element close menu
    } else {
      this.isOpen = false;
      this.page = 0;

      this.isAlarmOpen = false;
      this.alarmPage = 0;

    }
    if ($event.target.innerHTML === 'Settings') {
      this.isOpen = false;
      this.page = 0;

      this.isAlarmOpen = false;
      this.alarmPage = 0;
    }
  }

  clickedAlarm($event: any) {
    sessionStorage.setItem('substationId', $event.substationId);
    this.router.navigateByUrl(`/operations/create/${$event.substationName}?substationId=${$event.substationId}&assetId=${$event.assetId}`);

    this.isAlarmOpen = false;
  }

  acknowledgedAlarm($event: any) {
    if ($event.alarm.isActive) return;

    /*
    var i1 = this.appSvc.allAlarms.indexOf($event.alarm);
    var i2 = this.appSvc.alarms.indexOf($event.alarm);

    if (i1 > -1)
      this.appSvc.allAlarms.splice(i1,1);
    */
    this.getAlarms();
  }

  clickNotification($event: any) {
    if ($event && $event.notification.type.toLowerCase() === 'account' && $event.isRead) {
      if ($event.notification.subtype.toLowerCase() === 'add user to a role') {
        this.role.nativeElement.className = 'role-switch highlight animated shake';
        this.highlightNotification = _.cloneDeep($event.notification);
      }
      else if ($event.notification.subtype.toLowerCase() === 'add usr to team' || $event.notification.subtype.toLowerCase() === 'remove usr from team') {
        let ExecutionOwnerRole = _.find(this.roles, { role: 'Execution owner' });
        if (ExecutionOwnerRole)
          this.changeRole(ExecutionOwnerRole);
        else {
          let etlRole = _.find(this.roles, { role: 'Execution Team Lead' });
          if (etlRole)
            this.changeRole(etlRole);
          // else do nothing - a user was added to a team but they are neither a ExecutionOwner nor an ETL
        }
      }
    }

    // if (this.isMarkAllRead && !$event.isRead) {
    //   this.isMarkAllRead = false;
    // }

    if ($event.notification.type.toLowerCase() === 'performance'
      || $event.notification.category && $event.notification.category.toLowerCase() === 'planned') {
      this.isOpen = false;
      this.page = 0;
    }

    if ($event.notification.type.toLowerCase() === 'substation') {
      sessionStorage.setItem('substationId', $event.notification.substationIds[0].id);
      this.router.navigateByUrl(`/operations/create/${$event.notification.detail.match(/(?<=ubstation:\s*).*(?=\.)/)[0]}?substationId=${$event.notification.substationIds[0].id}`);
    }
  }

  checkHighlightRole(role) {
    if (this.highlightNotification) {
      return _.includes(this.highlightNotification.detail, role.name);
    } else {
      const result = _.filter(this.appSvc.allNotifications, notification => {
        return (notification.type === 'account' && _.includes(notification.detail, role.name));
      });
      return result && result.length > 0;
    }
  }

  clickMarkAllRead() {
    this.loading = true;
    this.notificationService.markAllNotificationsRead()
      .pipe(finalize(() => this.loading = false))
      .subscribe(() => {
        for (let not of this.appSvc.notifications) {
          not.isRead = true;
        }
      });
  }

  clickLoadMore(e) {
    e.stopPropagation();
    if (this.appSvc.notifications.length < this.appSvc.allNotifications.length) {
      this.page += 1;
    }
  }

  clickLoadMoreAlarms(e) {
    e.stopPropagation();
    if (this.appSvc.alarms.length < this.appSvc.allAlarms.length) {
      this.alarmPage += 1;
    }
  }

  clickBell() {
    this.isOpen = true;
  }

  clickAlarmIcon() {
    this.isAlarmOpen = true;
  }

  clickSettings() {
    const modalRef = this.modalService.open(NotificationSettingsComponent, { centered: true });
    modalRef.componentInstance.title = 'Notification Settings';
    modalRef.result.then(ret => {
    }, () => {
    });
  }

  clickAlarmSettings() {
    this.modalService.open(AlarmNotificationSettingsComponent, { centered: true, windowClass: "xl-modal" })
      .result.then((result) => {

      }, (reason) => {

      });
  }


  // ngAfterViewInit() {
  // setTimeout(() => {
  //   this.systemWarn.nativeElement.className = 'warn hide';
  //   this.systemDetail.nativeElement.className = 'detail animated fadeIn';
  // }, environment.systemNotificationShowDuration / 3);

  // setTimeout(() => {
  //   this.systemNotifications.nativeElement.className = 'system-notifications hide';
  // }, environment.systemNotificationShowDuration);
  // }

  clickSystemNotifcaionts() {
    const modalRef = this.modalService.open(SystemNotificationsComponent, { centered: true });
    modalRef.componentInstance.title = 'Connection errors to SCADA';
    modalRef.result.then(ret => {
    }, () => {
    });
  }
  getClass(){
    if (this.alarmList.find(x => x.severity == 'High'))
      return 'high';

    if (this.alarmList.find(x => x.severity == 'Medium'))
      return 'medium';

    if (this.alarmList.find(x => x.severity == 'Low'))
      return 'low';
  }
}
