<div class="filter-container">
  <i class="filter-icon icon" aria-hidden="true" (click)="toggle()"></i>

  <div class="filter-section" [class.hide]="!show">
    <div class="filter-section-container">
      <div class="header" (click)="toggle()">
        <i class="icon icon-filter" aria-hidden="true"></i>
        <span i18n>Column filter</span>
      </div>
      <div class="filter-option" *ngFor="let column of getConfigurableColumns()" (click)="toggleHide(column)">
        <i class="icon icon-checkbox" aria-hidden="true" [class.checked]="!column.hidden"></i>
        <span>{{column.text || column.name}}</span>
      </div>
    </div>
  </div>
</div>
