<div class="loading-box" *ngIf="loading">
    <div class="img" alt="loading"></div>
</div>
<div class="login-page" style="display: none;">
    <form (submit)="login()">
        <div class="login-container" *ngIf="state.loginFlow === 'normal'">
            <div class="cols">
                <div class="col">
                    <div>
                    </div>
                </div>
                <div class="col">
                    <div class="fieldset">
                        <div class="fs-lbl" i18n>
                            <span>Email</span>
                            <span *ngIf="showError && state.form.username" class="error-indicate" [ngClass]="{'invalid':!isEmail(state.form.username)}">
                                Should enter email</span>
                        </div>
                        <div class="fs-val">
                            <input type="text" (blur)="onBlurMethod()" maxlength="50" email pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                             class="text-ctrl" placeholder="Enter email" [(ngModel)]="state.form.username" (focus)="loginErrorMessage=null" name="state.form.username"
                            />
                        </div>
                    </div>
                    <div class="fieldset">
                        <div class="fs-lbl" i18n>
                            Password
                        </div>
                        <div class="fs-val">
                            <input type="password" maxlength="25" class="text-ctrl" placeholder="Enter password" [(ngModel)]="state.form.password" (focus)="loginErrorMessage=null" name="state.form.password" />
                        </div>
                    </div>
                    <div *ngIf="passwordError && state.form.username && state.form.password" class="col">
                        <div class="error">Invalid Email or Password</div>
                    </div>
                    <div class="login-error-message-container">
                        <span *ngIf="loginErrorMessage">{{ loginErrorMessage }}</span>
                    </div>
                    <div *ngIf="showCaptcha">


                        <re-captcha (resolved)="resolved($event)" required #recaptchaRef siteKey="6Le09scUAAAAAKHadwI5txFvDmJ2rVmWLMm-RvzU"></re-captcha>

                        <label #resetCaptchaa *ngIf="resetCaptcha">
      {{recaptchaRef.reset() ? recaptchaRef.reset() : ""}}
      </label>

                        <span *ngIf="CaptchanotVerified" class="error"> please verify the captcha</span>
                    </div>
                </div>
            </div>
            <div class="col-action col-md-12">
                <div class="span_col col-6">
                    <!-- <hr> -->
                    <span i18n>RelCare™</span>
                </div>
                <div class="col-6 row_below">
                    <div>
                        <span><a class="lnk" [routerLink]="['/passwordchange']">Change password </a></span>
                    </div>
                    <div>
                        <span><a class="lnk" [routerLink]="['/forgotPassword']">Forgot password </a></span>
                    </div>

                    <button type="submit" *ngIf="state.form.password && state.form.username" class="btn btn-login">Log
            in</button>

                    <button *ngIf="!state.form.username || !state.form.password" class="btn btn-login">Log in</button>


                </div>
            </div>
        </div>
    </form>
    <button (click)="OPCClientandPfModelLogin()" style="display:none" class="btn btn-deploy">Deploy</button>
    <div class="login-container" *ngIf="state.loginFlow === 'recovery'">
        <div class="cols">
            <div class="col">
                <div>
                    <img class="logo" src="../../../assets/i/logo.svg" alt="Logo" style="margin-top: 40px;">
                </div>
            </div>
            <div class="col">
                <div class="fieldset recover-email-style">
                    <div class="fs-lbl" i18n>
                        Email
                    </div>
                    <div class="fs-val">
                        <input type="email" class="text-ctrl" (blur)="onBlurEmail()" maxlength="50" [(ngModel)]="state.form.email" placeholder="Enter Email to recover" />
                    </div>
                    <span id="error" *ngIf="showEmailError" class="error-indicate" [ngClass]="{'invalid':!isEmail(state.form.email)}" i18n>Should enter
            Valid email</span>
                </div>
                <div class="row-action">
                    <div class="span_col col-11">
                        <span i18n>RelCare™</span>
                    </div>
                    <a (click)="sumitForgotPassword()" *ngIf="state.form.email" [ngClass]="{'invalid':!isEmail(state.form.email)}" class="btn" i18n>Submit</a>
                    <a *ngIf="!state.form.email" class="btn" i18n>Submit</a>
                </div>
            </div>
        </div>
    </div>
    <div class="login-container" *ngIf="state.loginFlow === 'emailSent'">
        <div class="cols">
            <div class="col">
                <div class="logo" i18n>Reliability Care</div>
            </div>
            <div class="col">
                <div class="fieldset recover-email-style">
                    <h5>{{emailSentMessage}}</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="login-container" *ngIf="state.loginFlow === 'language-selection'">
        <div class="cols">
            <div class="col" i18n>
                <div class="logo">Reliability Care</div>
            </div>
            <div class="col">
                <div class="fieldset">
                    <div class="fs-lbl" i18n>
                        Customer name
                    </div>
                    <div class="fs-val">
                        <input type="text" class="text-ctrl" />
                    </div>
                </div>
                <div class="fieldset">
                    <div class="fs-lbl" i18n>
                        Language
                    </div>
                    <div class="fs-val">
                        <select class="select-ctrl">
              <option i18n>English</option>
              <option i18n>Hindi</option>
            </select>
                    </div>
                </div>

                <div class="row-action">
                    <a (click)="next()" class="btn" i18n>Next</a>
                </div>
            </div>
        </div>
    </div>
</div>