import { Component, Input, OnChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ApplicationService } from 'src/services/application.service';
import { WidgetConditionalLoading } from '../../../../../models/widget-conditional-loading';
import { WidgetConfig, WidgetType, VisualDataConfig } from '../../models/widget';
import { ConstService } from '../../../../../../services/const.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { optionItem } from '../../models/optionItem';

export interface LineChartSettings {
  title: any;
  yLabel: any;
  yCustomLabel: any;
  xLabel: any;
  xCustomLabel: any;
  xMin: number;
  xMax: number;
  xTicks: number;
  xUnit?: string;
  yUnit?: string;
  yProperties: Array<VisualDataConfig>;
  yTicks: number;
  yMin: number;
  yMax: number;
  xAxisProperty?: string;
  xColumns?: Array<any>;
  xColors?: Array<any>;
  showVertLines?: boolean;
  showHoriLines?: boolean;
  controlValuesColor?: string;
  xLabels?: Array<any>;
  hasControlValues?: boolean;
  minControlValue?: string;
  maxControlValue?: string;
  isDependency?: boolean;
}

@Component({
  selector: 'app-line-widget',
  templateUrl: './line-widget.component.html',
  styleUrls: []
})
export class LineWidgetComponent implements OnChanges {
  @Input() widget: WidgetConfig;
  @Input() isPreview: boolean;
  @Input() displayConfiguration: WidgetConditionalLoading;
  @Input() dateRangeLabel = '';
  @Input() availablePropertiesForXAxis: Array<optionItem>;

  isLoading = false;
  displayData: any;
  chartSettings: LineChartSettings;
  constructor(private appService: ApplicationService, private consts: ConstService, private router: Router) { }

  ngOnChanges() {
    this.getWidgetData();

  }

  setChartSettings() {
    try {
      this.widget.config.visualConfig.xAxis.unit = this.widget.config.dataConfig.xAxis.label;
      this.chartSettings = {
        title: this.widget.config.visualConfig.chartTitle,
        yLabel: this.widget.config.dataConfig.yAxis.label,
        yUnit: this.widget.config.visualConfig.yAxis.unit,
        xUnit: this.widget.config.visualConfig.xAxis.unit,
        yCustomLabel: this.widget.config.visualConfig.yAxis.customName,
        xLabel: this.widget.config.visualConfig.xAxis.unit,
        xCustomLabel: this.widget.config.visualConfig.xAxis.customName,
        xMin: this.widget.config.dataConfig.xAxis.rangeMin as number,
        xMax: this.widget.config.dataConfig.xAxis.rangeMax as number,
        xTicks: this.widget.config.visualConfig.xAxis.ticksNumber,
        yProperties: this.widget.config.visualConfig.yAxis.visualConfigs,
        yTicks: this.widget.config.visualConfig.yAxis.ticksNumber,
        yMin: this.widget.config.dataConfig.yAxis.minControlValue,
        yMax: this.widget.config.dataConfig.yAxis.maxControlValue,
        controlValuesColor: this.widget.config.visualConfig.controlValueColor,
        hasControlValues: this.widget.config.dataConfig.yAxis.hasControlValues,
        minControlValue: this.widget.config.dataConfig.yAxis.minControlValue.toString(),
        maxControlValue: this.widget.config.dataConfig.yAxis.maxControlValue.toString(),
        showVertLines: this.widget.config.visualConfig.showVerticalLines,
        showHoriLines: this.widget.config.visualConfig.showHorizontalLines,
        isDependency: this.widget.config.dataConfig.isTimeSeries === false
      };
    } catch {
      this.displayData = {};
    }
    finally {
      this.isLoading = false;
    }
  }

  /**
   * Get widget data passing data config.
   */
  getWidgetData() {
    if (this.widget.widgetSystemType && !this.router.url.includes('report-dashboardUrl')) {
      this.appService
        .getSystemWidgetDataByWidgetId(this.widget.config.sourceConfig.assetId, this.widget.widgetSystemType)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe({
          next: (resp) => {
          this.displayData = resp;
          this.generateChartSettings();
        }, error: () => {this.isLoading = false;}
        });
      return;
    }  else if (this.widget.widgetSystemType && this.router.url.includes('report-dashboardUrl')) {
      this.appService
        .getSystemWidgetDataByWidgetIdUrl(this.widget.config.sourceConfig.assetId, this.widget.widgetSystemType)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe({
          next: (resp) => {
          this.displayData = resp;
          this.generateChartSettings();
        }, error: () => {this.isLoading = false;}});
      return;
    }


    if (this.isPreview == undefined) {
      this.appService.getlineChartData(this.widget.id).pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (resp) => {
        // map response to data
        this.displayData = resp;
        this.setChartSettings();
      }, error: () => {this.isLoading = false;}});
    } else if (this.isPreview) {
      this.appService.getWidgetFakeData(WidgetType.LINE, this.widget).pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (resp) => {
        try {
          this.displayData = resp;
          this.setChartSettings();
          this.setControlValuesForFakeData();
        }
        finally {
          this.isLoading = false;
        }
      },error: () => {this.isLoading = false;}});
    } else if (this.router.url.indexOf('report-dashboardUrl') > -1) {
      this.appService.getWidgetDataByWidgetIdUrl(this.widget.id, WidgetType.LINE, this.displayConfiguration)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (resp) => {
        try {
          this.displayData = resp.series;
          this.setChartSettings();
          this.prepareSettingsForHardcoded();
        }
        finally {
          this.isLoading = false;
        }
      }, error: () => {this.isLoading = false;} });
    } else {
      this.appService.getWidgetDataByWidgetId(this.widget.id, WidgetType.LINE, this.displayConfiguration)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next:  (resp) => {
        try {
          this.displayData = resp.series;
          this.setChartSettings();
          this.prepareSettingsForHardcoded();
        }
        finally {
          this.isLoading = false;
        }
      }, error: () => {this.isLoading = false;}
      });
    }
  }
  setControlValuesForFakeData() {
    this.chartSettings.xMin = 0;
    this.chartSettings.xMax = 10;
    this.chartSettings.isDependency = false;
  }

  generateChartSettings() {
    this.chartSettings = {
      title: this.widget.config.visualConfig.chartTitle,
      yProperties: [
        {
          name: 'Loading of asset',
          color: '#4c85ff'
        }
      ],
    } as LineChartSettings;
  }

  private prepareSettingsForHardcoded() {
    const oldSettings = _.cloneDeep(this.chartSettings.yProperties);

    for (let i = 0; i < this.displayData.length; i++) {
      const element = this.displayData[i];

      this.chartSettings.yProperties[i] = {
        name: element.name,
        color: this.chartSettings.yProperties[i].color,
        id: this.chartSettings.yProperties[0].id
      };
    }

    for (let i = 0; i < oldSettings.length; i++) {
      const element = oldSettings[i];

      if (!this.chartSettings.yProperties.map(x => x.name).includes(element.name)) {
        if (!this.chartSettings.yProperties.map(x => x.name).includes(element.name + ' - 1')) {
          this.chartSettings.yProperties.push(element);
        } else {
          const y = this.chartSettings.yProperties.find(x => x.name === element.name + ' - 1');
          y.color = element.color;
        }
      }
    }
  }

}
