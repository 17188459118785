import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SettingsService } from "./settings.service";
import { Settings } from "src/app/settings";


@Injectable({ providedIn: 'root' })
export class SettingsHttpService {

    constructor(private http: HttpClient, private settingsService: SettingsService) {
    }

    async initializeApp(): Promise<any> {
        this.http.get('assets/settings.json').subscribe(response => {
              this.settingsService.settings = <Settings>response;
          });
          return await this.http.get('assets/settings.json').toPromise();
              }
                          }