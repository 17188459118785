import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  constructor() { }

  //The set method is use for encrypt the value.
  set(keys, value) {
    /*var key= CryptoJS.enc.Utf8.parse(keys); 
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
*/
    // var key = CryptoJS.enc.Utf8.parse('7061737323313233');
    // var iv = CryptoJS.enc.Utf8.parse('7061737323313233');
    // var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    //   {
    //     keySize: 128 / 8,
    //     iv: iv,
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    //   });

     var encryptValue = btoa(value.toString());

      return encryptValue;
    ////return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  //   get(keys, value){
  //     var key = CryptoJS.enc.Utf8.parse(keys);
  //     var iv = CryptoJS.enc.Utf8.parse(keys);
  //     var decrypted = CryptoJS.AES.decrypt(value, key, {
  //         keySize: 128 / 8,
  //         iv: iv,
  //         mode: CryptoJS.mode.CBC,
  //         padding: CryptoJS.pad.Pkcs7
  //     });

  //     return decrypted.toString(CryptoJS.enc.Utf8);
  //   }
}