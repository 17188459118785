import { Component, OnInit } from '@angular/core';
import { SldUtilService } from '../../containers/sld-editor/services/util.service'
import { HelperService } from '../../containers/sld-editor/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-export-substation-data',
  templateUrl: './export-substation-data.component.html',
  styleUrls: ['./export-substation-data.component.scss'],
  providers: [ SldUtilService, HelperService ]
})
export class ExportSubstationDataComponent implements OnInit {
  loading = false;
  filterColumns = []
  title;
  showDownload:boolean = false;
  constructor( private utilService: SldUtilService, private route: ActivatedRoute ) { }

  ngOnInit() {
    console.log("initialise app");
    if(this.route.snapshot.queryParams['isExport'] === "true" || this.route.snapshot.queryParams['isExport'] === true){
        this.title = 'Export Substation Data';
        this.showDownload = true;
    } else {
      this.title = 'Import Substation Data'
    }
    this.loading =true;
     this.utilService.getDownloadExcelFormJob(this.route.snapshot.queryParams['substationId'],this.route.snapshot.queryParams['isExport']).subscribe({next:res => {
    // this.utilService.getDownloadExcelFormJob('ef0c3442-6333-4149-8b89-98bf2dfcc9c2','true').subscribe(res => {
      this.filterColumns = res.sldExportSubstationDtosList;
      this.filterColumns.forEach(item => {
        item.excelRequrestTs = moment.utc(item.excelRequrestTs).local().toDate();
        item.excelCompleteTs = moment.utc(item.excelCompleteTs).local().toDate();
      });
      this.loading =false;
    },error:() => {
      this.loading=false;
    }});
  }
  openDownloadModal(item) {
      const sliceSize=512
      let byteCharacters = atob(item.documentObj);
      let byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize);

          let byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
          let byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
      }
      const blob= new Blob(byteArrays, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          FileSaver.saveAs(blob, 'Component Data.xlsx');
  }
}
