import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { CreateUserProfileComponent } from './components/create-user-profile/create-user-profile.component';
// import {CreateSwmsComponent } from './containers/create-swms/create-swms.component';
// import { SldEditorComponent } from './containers/sld-editor/sld-editor.component';
// import { ProjectModellingComponent } from './containers/project-modelling/project-modelling.component';
// import { UserTypeResolverService } from '../services/user-type-resolver.service';
import { LoginComponent } from './containers/login/login.component';
import { UserConfirmationComponent } from './containers/user-confirmation/user-confirmation.component';
// import { PageNotFoundComponent } from '../app/page-not-found/page-not-found.component';
// import { PolicyComponent } from './containers/policy/policy.component';
// import { policyRoutes } from './containers/policy/policy-routing.module';
// import { assetRoutes } from './containers/assets/assets-routing.module';
// import { AssetsComponent } from './containers/assets/assets.component';
// import { SubStationInfoComponent } from './containers/sub-station-info/assets.component';
// import { PlanningComponent } from './containers/planning/planning.component';
// import { planningRoutes } from './containers/planning/planning-routing.module';
// import {ExecutionComponent} from './containers/execution/execution.component';
// import {executionRoutes} from './containers/execution/execution-routing.module';
// import {ReliabilityCenterComponent} from './containers/reliability-center/reliability-center.component';
// import {reliabilityCenterRoutes} from './containers/reliability-center/reliability-center-routing.module';
// import { WidgetsComponent } from './containers/widgets/widgets.component';
import { ExportSubstationDataComponent } from './containers/export-substation-data/export-substation-data.component';
import { AppAuthGuard } from './utility/app.guard';


// import { SldEditorComponent } from './containers/sld-editor/sld-editor.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
    canActivate: [AppAuthGuard]

    // ////canActivate: : : [AuthGuard],
    // resolve: {
    //   userType: UserTypeResolverService,
    // }
  },
  {
    path: 'importorexportComponentData',
    component: ExportSubstationDataComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: 'userConfirmation/:id',
    component: UserConfirmationComponent,
  },
  {
    path: 'dashboard',
    // loadChildren: () => import('./containers/dashboard/dashboard.module').then(m => m.DashboardModule),
    loadChildren: () => import('./containers/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AppAuthGuard]
    // component: DashboardComponent,
    // children: dashboardRoutes,
    //canActivate: : [AuthGuard],
    // resolve: {
    //   userType: UserTypeResolverService,
    // }
  },
  {
    path: 'operations',
    loadChildren: () => import('./containers/operations/operations.module').then(m => m.OperationsModule),
    canActivate: [AppAuthGuard],
    // loadChildren: () => import('./containers/operations/operations.module').then(m => m.OperationsModule),
    data: { title: 'Operations' },
    //canActivate: : [AuthGuard]
  },

  {
    path: 'project-modelling/:id',
    // loadChildren: () => import('./containers/project-modelling/project-modelling.module').then(m => m.ProjectModellingModule),
    loadChildren: () => import('./containers/project-modelling/project-modelling.module').then(m => m.ProjectModellingModule),
	canActivate: [AppAuthGuard],
    // component: ProjectModellingComponent,
    data: { title: 'Login' },
    //canActivate: : [AuthGuard]
  },
  {
    path: 'sld-editor',
    // loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
    loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
	canActivate: [AppAuthGuard]
    // component: SldEditorComponent,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'sld-editor/:view',
    // loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
    loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
	canActivate: [AppAuthGuard]
    // component: SldEditorComponent,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'sld-editor/:view/:substationId',
    // loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
    loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
	canActivate: [AppAuthGuard]
    // component: SldEditorComponent,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'policy',
    // loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
    loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
	canActivate: [AppAuthGuard]
    // component: PolicyComponent,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'policy/:id/:equip/:menu',
    // loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
    loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
	canActivate: [AppAuthGuard]
    // children: policyRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'assets/:id',
    // loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
    loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
	canActivate: [AppAuthGuard]
    // children: assetRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'assets/:id/:view',
    // loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
    loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
	canActivate: [AppAuthGuard]
    // children: assetRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'substationinfo',
    // loadChildren: () => import('./containers/sub-station-info/assets.module').then(m => m.SubStationInfoModule),
    loadChildren: () => import('./containers/sub-station-info/assets.module').then(m => m.SubStationInfoModule),
	canActivate: [AppAuthGuard]
    // children: assetRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'planning',
    // loadChildren: () => import('./containers/planning/planning.module').then(m => m.PlanningModule),
    loadChildren: () => import('./containers/planning/planning.module').then(m => m.PlanningModule),
	canActivate: [AppAuthGuard],
    // children: planningRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'execution',
    // loadChildren: () => import('./containers/execution/execution.module').then(m => m.ExecutionModule),
    loadChildren: () => import('./containers/execution/execution.module').then(m => m.ExecutionModule),
	canActivate: [AppAuthGuard],
    // children: executionRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'reliability-center',
    // loadChildren: () => import('./containers/reliability-center/reliability-center.module').then(m => m.ReliabilityCenterModule),
    loadChildren: () => import('./containers/reliability-center/reliability-center.module').then(m => m.ReliabilityCenterModule),
	canActivate: [AppAuthGuard],
    // children: reliabilityCenterRoutes,
    //canActivate: : [AuthGuard]
  },
  {
    path: 'create-swms',
    // loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
    loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
	canActivate: [AppAuthGuard],
    data: { title: 'SWMS' },
    //canActivate: : [AuthGuard]
  },
  {
    path: 'widgets',
    // component: WidgetsComponent,
    // loadChildren: () => import('./containers/widgets/widgets.module').then(m => m.WidgetsModule),
    loadChildren: () => import('./containers/widgets/widgets.module').then(m => m.WidgetsModule),
	// canActivate: [AppAuthGuard],
    data: { title: 'widgets' },
    //canActivate: : [AuthGuard]
  },
  {
    path: 'swms/:param1/:param2',
    // loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
    loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
	canActivate: [AppAuthGuard],
    data: { title: 'SWMS' },
    //canActivate: : [AuthGuard]
  },
  {
    path: 'derivative-alarm',
    loadChildren: () => import('./containers/derivative-alarm/derivative-alarm.module').then(m => m.DerivativeAlarmModule),
    canActivate: [AppAuthGuard],
    data: { title: 'derivative-alarm' },
  },
  // {
  //   path: '**',
  //   pathMatch: 'full',
  //   component: PageNotFoundComponent
  // },
  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  declarations: [CreateUserProfileComponent]
})
export class AppRoutingModule { }
