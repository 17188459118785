import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HelperService } from './helper.service';
import { ApplicationService } from './application.service';



/**
 * The auth user service
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private helper: HelperService, public router: Router, private appServ: ApplicationService) { }

  /**
   * Overriden canActivate method of interface
   * @return true if user is logged in else false
   */

  checkforFalse() {
    this.router.navigate(['login']);
    sessionStorage.clear();
    localStorage.clear();
    return false;
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this.getUser()) {
      this.router.navigate(['login']);
      sessionStorage.clear();
      localStorage.clear();
      return false;
    }
    //const user = this.appServ.getUserRoles();
    const priviledges = this.appServ.getUserPrivilages();
    if (state.url === '/dashboard/members-privileges') {
      if (priviledges.MemberAndPriviledge) {
        return true;
      } else {
        this.checkforFalse();
      }
    }
    else if (state.url.includes('/dashboard/project-modelling')) {
      if (priviledges.OutageAreasEdit || priviledges.OutageAreasView) {
        return true;
      } else {
        this.checkforFalse();
      }
    }
    else if (state.url.includes('/dashboard/reliability-center')) {
      if (priviledges.InformationexplorerEdit
        || priviledges.InformationexplorerView) {
        return true;
      } else {
        this.checkforFalse();
      }
    }
    else if ((state.url === '/dashboard/create-project') || (state.url === '/dashboard') 
    || (state.url.includes('/dashboard/project/'))) {
      if (priviledges.CreateProjectsEdit || priviledges.CreateProjectsView) {
        return true;
      } else {
        this.checkforFalse();
      }
    }
    else if (state.url === '/dashboard/project-settings') {
      if (priviledges.KnowledgeBankEdit || priviledges.KnowledgeBankView) {
        return true;
      } else {
        this.checkforFalse();
      }
    }
    else if (state.url === '/dashboard/task-criterias') {
      if (priviledges.OpexEdit || priviledges.OpexView ||
        priviledges.TaskCriteriasPriviledgeEdit || priviledges.TaskCriteriasPriviledgeView) {
        return true;
      } else {
        this.checkforFalse();
      }
    }
    else if (state.url === '/sld-editor') {
      if (priviledges.GISSLDEditorEdit || priviledges.GISSLDEditorView) {
        return true;
      } else {
        this.checkforFalse();
      }
    }
    return true;
  }

  /**
   * gets user object stored in sessionStorage
   * @return user object
   */
  getUser(): any {
    return sessionStorage.getItem('UserId') ? true : false;
  }

  getUserPrpoerties(): any {
    return sessionStorage.getItem('UserId') ? true : false;
  }


  /**
   * login function
   * @return Observable of user object
   */
  // login(username, password): any {
  //   const hashedUsername = cryptojs.HmacSHA256(username, 'abbrcs').toString();
  //   const hashedPassword = cryptojs.HmacSHA256(password, 'abbrcs').toString();

  //   return this.helper
  //     .get('/assets/data/UserCredentials.json')
  //     .pipe(map(credentials => {
  //       return credentials.filter(c => c.hashedUsername === hashedUsername && c.hashedPassword === hashedPassword)[0];
  //     }))
  //     .pipe(tap(match => {
  //       if (!match) {
  //         throw new Error('No such user exists');
  //       }
  //       sessionStorage.setItem('user', JSON.stringify(match));
  //     }));
  //   // }

}
