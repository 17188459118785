import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DatePipe, LocationStrategy, HashLocationStrategy, PathLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyInterceptor } from '../services/token-interceptor.service';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { HelperService } from '../services/helper.service';
import { BrowserWindowRef } from '../services/window.service';

import { ManageProjectService } from '../services/manageProject.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LoginComponent } from './containers/login/login.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';

// import { DashboardModule } from './containers/dashboard/dashboard.module';
// import { SldEditorModule } from './containers/sld-editor/sld-editor.module';
// import { MapComponent } from './containers/project-modelling/map/map.component';
// import { ProjectModellingModule } from './containers/project-modelling/project-modelling.module';
// import { AddEditProjectComponent } from './containers/dashboard/add-edit-project/add-edit-project.component';
import { UserConfirmationComponent } from './containers/user-confirmation/user-confirmation.component';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxPaginationModule } from 'ngx-pagination';
// import { PolicyModule } from './containers/policy/policy.module';
// import { ExecutionModule } from './containers/execution/execution.module';
import { ReliabilityCenterComponent } from './containers/reliability-center/reliability-center.component';
// import { WidgetsComponent } from './containers/widgets/widgets.component';
// import { AssetsModule } from './containers/assets/assets.module';
// import { SubStationInfoModule } from './containers/sub-station-info/assets.module';
// import { PlanningModule } from './containers/planning/planning.module';
// import { CreateSwmsModule } from './containers/create-swms/create-swms.module';
// import { SharedModule } from './components/shared.module';
import { DragulaModule } from 'ng2-dragula';
import * as go from 'gojs';
// import {ReliabilityCenterModule} from './containers/reliability-center/reliability-center.module';
import { EncrDecrService } from '../services/EncrDecrService';
import { MainHeaderService } from './components/main-header/main-header.service';
import { DashboardService } from './containers//dashboard/dashboard.service';
import { NgxImageCompressService } from 'ngx-image-compress';
// import { WidgetsModule } from './containers/widgets/widgets.module';
import { AlarmItemComponent } from './components/main-header/alarm-item/alarm-item.component';
import { NotificationItemComponent } from './components/main-header/notification-item/notification-item.component';
import { NotificationSettingsComponent } from './components/main-header/modal/notification-settings/notification-settings.component';
import { LogoutConfirmationModalComponent } from './components/main-header/modal/logout-confirmation-modal/logout-confirmation-modal.component';
import { SuccesspopupComponent } from './containers/dashboard/modals/successpopup/successpopup.component';
import { GeneralUserConfirmationModalComponent } from './containers/dashboard/modals/general-user-confirmation-modal/general-user-confirmation-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { AddSubystemModalComponent } from './containers/dashboard/modals/add-subystem-modal/add-subystem-modal.component';
import { SettingsHttpService } from 'src/services/settings.http.service';
import { ExportSubstationDataComponent } from './containers/export-substation-data/export-substation-data.component';
import { PopUpService } from 'src/services/popUp.service';
import { AlarmNotificationSettingsComponent } from './components/main-header/modal/alarm-notification-settings/alarm-notification-settings.component';
import { SharedModule } from './components/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouteReuseStrategy } from '@angular/router';
import { AppRouteReuseStrategy } from './app-route-reuse-strategy';
import { WidgetsService } from './components/dashboard/widgets/edit-widget-dashboard/services/widgets.service';


import { KeycloakAngularModule , KeycloakService} from 'keycloak-angular';
import { initializeKeycloak } from '../app/utility/app.init';
import { BnNgIdleService } from 'bn-ng-idle';
import { ReLoginOpcScenerioComponent } from './containers/re-login-opc-scenerio/re-login-opc-scenerio.component';
import { SldUtilService } from './containers/sld-editor/services/util.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
};

export function app_Init(settingsHttpService: SettingsHttpService) {
  return () => settingsHttpService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // MapComponent,
    MainHeaderComponent,
    MainFooterComponent,
    // AddEditProjectComponent,
    UserConfirmationComponent,
    ReliabilityCenterComponent,
    // WidgetsComponent,
    NotificationItemComponent,
    AlarmItemComponent,
    NotificationSettingsComponent,
    LogoutConfirmationModalComponent,
    SuccesspopupComponent,
    GeneralUserConfirmationModalComponent,
    AddSubystemModalComponent,
    ExportSubstationDataComponent,
    AlarmNotificationSettingsComponent,
    ReLoginOpcScenerioComponent,
  ],
  entryComponents: [
    LogoutConfirmationModalComponent,
    NotificationSettingsComponent,
    SuccesspopupComponent,
    GeneralUserConfirmationModalComponent,
    AddSubystemModalComponent,
    AlarmNotificationSettingsComponent,
    ReLoginOpcScenerioComponent,
  ],
  imports: [
    MatExpansionModule,
    PerfectScrollbarModule,
    NgxPaginationModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    KeycloakAngularModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
      enableHtml: true,
      timeOut: 20000,
      preventDuplicates: true,
      maxOpened: 1
    }),
    // WidgetsModule,
    // DashboardModule,
    // SldEditorModule,
    // PolicyModule,
    // AssetsModule,
    // SubStationInfoModule,
    // PlanningModule,
    // ExecutionModule,
    //  ProjectModellingModule,
    // CreateSwmsModule,
    // ReliabilityCenterModule,
    SharedModule,
    DragulaModule.forRoot(),
    // Ng4LoadingSpinnerModule.forRoot(),

  ],
  providers: [
    SldUtilService,
    HelperService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService,SettingsHttpService],
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptor,
      multi: true
    },
    BnNgIdleService,
    BrowserWindowRef,
    MainHeaderService,
    DashboardService,
    HelperService,
    ManageProjectService,
    EncrDecrService,
    DatePipe,
    NgxImageCompressService,
    SettingsHttpService,
    PopUpService,
    WidgetsService,
    { provide: LocationStrategy, useClass: HashLocationStrategy, useValue: 'en' },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APP_BASE_HREF, useValue: ''}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor(private windowRef: BrowserWindowRef) {
    // set global function to get current substation
    windowRef.nativeWindow['getSubstation'] = () => {
      const rawSubstation = localStorage.getItem('substation');
      if (!rawSubstation) { return {}; }

      let substation;
      try {
        substation = JSON.parse(rawSubstation);
      } catch (e) {
        substation = {};
      }
      return substation;
    };
    // GoJS License Key Added
    (go as any).Diagram.licenseKey =
      '73f944e1b76431b700ca0d2b113f69ee1bb37a3a9ed11ea05e5141f7ee0d684070c9ed7958d68fc3c0e848a84a7dc1d98ec63d20954a0433b561d6884ae782ffb43473e21100458af7012391cef829f6fb2879f597b472a28a7d89a6eaafc7c908bba0834ccd0cbe3b6800640678af18e7facb6bfa03cf4c746f87a5fb';
  }

}
